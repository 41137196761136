
    import {defineComponent, ref, onMounted, reactive} from 'vue';
    import {close1, getOperatorList} from '@/network/seller';
    import {message} from "ant-design-vue";
    import moment from 'moment';
    import baseUrl from '@/util/api'

    interface DataItem {
        id: number;
        product: string;
        sku: string;
        site: string;
        need1: string;
        need2: string;
        need3: string;
        need4: string;
        need5: string;
        link1: string;
        link2: string;
        link3: string;
        link4: string;
        link5: string;
        creator: string;
        fixer: string;
        status: number;
        type: number;
        create_time: number;
        get_time: number;
        finish_time: number;
    }

    interface WhereItem {
        page: number;
        size: number;
        type?:string;
        status?:string;
        product?:string;
        sku?:string;
        site?:string;
    }

    export default defineComponent({
        name: "Selist1",
        setup() {
            const uid = window.localStorage.getItem('uid');
            const data = ref<DataItem[]>([]);
            const where = ref<WhereItem>({
                page: 1,
                size: 20,
                type:undefined,
                status:undefined,
                product:'',
                sku:'',
                site:'',
            });
            const columns = [
                {title: 'ID', width: 80, dataIndex: 'id', key: 'id', fixed: 'left'},
                {
                    title: '工单类型', dataIndex: 'type', key: 'type', width: 100, fixed: 'left',slots: {
                        customRender: 'type',
                    }
                },
                {title: '产品名', dataIndex: 'product', key: 'product', width: 100},
                {title: 'sku', width: 150, dataIndex: 'sku', key: 'sku'},
                {title: '网站名称', dataIndex: 'site', key: 'site', width: 100},
                {title: '需求1', dataIndex: 'need1', key: 'need1', width: 150},
                {
                    title: '链接1', dataIndex: 'link1', key: 'link1', width: 100, ellipsis: true, slots: {
                        customRender: 'link1',
                    }
                },
                {title: '工单创建人', dataIndex: 'creatorname', key: 'creatorname', width: 150},
                {title: '工单接单人', dataIndex: 'fixername', key: 'fixername', width: 150},
                {
                    title: '工单状态', dataIndex: 'status', key: 'status', width: 150, slots: {
                        customRender: 'status',
                    }, sorter: (a, b) => a.status - b.status,
                },
                {
                    title: '创建时间', dataIndex: 'create_time', key: 'create_time', width: 150,
                    customRender: (text, record, index) => {
                        return moment.unix(text.text).format('YYYY-MM-DD HH:mm');
                    }, sorter: (a, b) => a.create_time - b.create_time,
                },{
                    title: '接单时间', dataIndex: 'get_time', key: 'get_time', width: 150,
                    customRender: (text, record, index) => {
                        if(text.text){
                            return moment.unix(text.text).format('YYYY-MM-DD HH:mm');
                        }

                    }, sorter: (a, b) => a.get_time - b.get_time,
                },
                {
                    title: '完成时间', dataIndex: 'finish_time', key: 'finish_time', width: 150,
                    customRender: (text, record, index) => {
                        if(text.text){
                            return moment.unix(text.text).format('YYYY-MM-DD HH:mm');
                        }

                    }, sorter: (a, b) => a.finish_time - b.finish_time,
                },
                {
                    title: '操作',
                    key: 'operation',
                    fixed: 'right',
                    width: 120,
                    slots: {customRender: 'action'},
                },
            ];
            const loading = ref(false);
            const list = (where) => {
                loading.value = true;
                getOperatorList({'uid': window.localStorage.getItem('uid'), ...where}).then((response) => {
                    loading.value = false;
                    if (response.status == 'done') {
                        data.value = response.list;
                        pagination.total = response.total;
                        //message.success(`${response.msg} `);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            };
            /*const handleChange = (value: string) => {
                where.value.type = value;
            };
            const handleChange1 = (value: string) => {
                where.value.status = value;
            };*/
            const search = () => {
                where.value.page = 1;
                list(where.value);
            };
            const pagination = reactive({
                total: 0,
                pageSize: 20,
            });
            const handleTableChange = (pagination, filters, sorter) => {
                where.value.page = pagination.current;
                list(where.value);
            };
            const visible = ref<boolean>(false);
            const selectId = ref<number>();
            const userRole = ref<number>(0);
            /*const role = ()=>{
                getRole({'uid':uid}).then((response) => {
                    if (response.status == 'done') {
                        userRole.value = response.role;
                    } else {
                        console.log(response.msg);
                    }
                });
            }*/
            const onClose = (id) => {
                close1({'id': id}).then((response) => {
                    if (response.status == 'done') {
                        message.success(`${response.msg} `);
                        list(where.value);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            };
            onMounted(() => {
                /*role();*/
                list(where.value);
            });
            return {
                loading,
                data,
                columns,
                where,
                /*handleChange,
                handleChange1,*/
                search,
                handleTableChange,
                pagination,
                visible,
                userRole,
                baseUrl: baseUrl.apiUrl,
                onClose
            };
        },
    });
